//Created by buildtools at 2024-11-05T13:24:25.062Z
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import React, { Fragment } from 'react';
import { useDispatch, useSelector, connect } from "react-redux";
import { useEffect } from "react";
import { cloneDeep } from "lodash";
import jp from "jsonpath";
import { v4 as uuidv4 } from 'uuid';
import dot from 'dot-object';
import merge from 'deepmerge';
import DROOLS from 'drools';
import DROOLS_pckg from '../../node_modules/drools/package.json';
import createDynamicJourneyRenderer from "@vf-djr/renderer";
import mapStateToPropsPlugin from "@vf-djr-plugins/map-state-to-props/lib";
import componentRenderingPlugin from "@vf-djr-plugins/component-rendering";
import conditionalRenderingPlugin from "@vf-djr-plugins/conditional-rendering";
import Box from 'components/Box';
import Acronym from 'components/Acronym';
import Sidebar from 'components/Sidebar';
import PrivateRoute from '../routes/PrivateRoute';
import OauthCode from 'components/OauthCode';
import NotFoundPage from '../components/NotFound';
import Signin from '../components/Signin';
import Logout from '../components/Logout';
import { store } from 'store/configureStore';
import TranslationService from 'services/TranslationService';
import { setProps } from "actions/propActions";
import * as functions from 'functions';

import { history } from '../sharedHistory';

const selectors = {
  not: value => !value,
  toString: value => value?.toString(),
};


const apis = {
};

store.dispatch({type:'SET_IS_AUTH_APP', payload: false });

const createBaseUrl = (pageId) => {
  const pages = [
    { id: "672a0add59c19d7196742d26", isHome: true, title: "home" },
  ];
  const hasAuth = false;
  let baseUrl = '/';

  const currentPage = pages.find((p) => p.id === pageId);

  if (currentPage?.isHome)
    baseUrl += hasAuth ? 'dashboard' : '';
  else if (currentPage && !currentPage.isHome)
    baseUrl += `page/${currentPage.title}`;

  baseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
  
  return baseUrl;
};

const propsAndApis = (props, pckg) => ({
  __baseUrl: createBaseUrl(props.__pageId),
  ...props,
  ...Object.fromEntries(Object.entries(apis).filter(([k, v]) => (pckg["app-framework"] && pckg["app-framework"]["apis"] && pckg["app-framework"]["apis"].includes(k)) ? v : null))
});

const DROOLS_wrapper = props => {
  const dispatch = useDispatch();
  const perInstanceDispatch = action => {
    const modifiedAction = {
      ...action,
      instanceId: props.__instanceId
    };
    dispatch(modifiedAction);
  };
  const language = useSelector(state => state.language && state.language.language);
  const reduxPerInstance = DROOLS_pckg['app-framework'] && DROOLS_pckg['app-framework'].reduxPerInstance;
  const config = useSelector(state => (state.config.data && state.config.data.widgets["drools"] && state.config.data.widgets["drools"][props.__instanceId]) ?? undefined);
  const perInstanceState = useSelector(state => (state['drools'] ?? {})[props.__instanceId]) ?? undefined;
  const singleState = useSelector(state => state['drools']) ?? undefined;
  const state = reduxPerInstance ? perInstanceState : singleState;
  const passedDispatch = reduxPerInstance ? perInstanceDispatch : dispatch;
  return (
    <DROOLS
      {...propsAndApis(props, DROOLS_pckg)}
      dispatch={passedDispatch}
      config={config}
      state={state}
      language={language}
      i18n={TranslationService.moduleT('drools')}>
    </DROOLS>
  );
};

const noDcl = props => {
  const {smWidth, mdWidth, ...rest} = props;
  return rest;
};

const propsAndActions = actionPropNames => props =>
    Object.fromEntries(Object.entries(props).map(([propName, val]) => {
      if(actionPropNames.includes(propName)) {
        const newVal = (event) => {
          if(val.function) return selectors[val.function](event);
          const state = store.getState();
          const { type, instanceId, payload, payloadFromState, payloadFromEvent } = val;
          if(typeof (payload || payloadFromState || payloadFromEvent) === "object") {
            const pfs = Object.fromEntries(
                Object.entries(payloadFromState || {}).map(([k, v]) => [k, dot.pick(v, state)]));
            dot.object(pfs);
            const pfe = Object.fromEntries(
                Object.entries(payloadFromEvent || {}).map(([k, v]) => [k, dot.pick(v, event)]));
            dot.object(pfe);
            const newPayload = merge.all([payload, pfs, pfe]);
            store.dispatch({type, instanceId, payload: newPayload});
          } else {
            const newPayload = payload
                || (payloadFromState && dot.pick(payloadFromState, state))
                || (payloadFromEvent && dot.pick(payloadFromEvent, event));
            store.dispatch({type, instanceId, payload: newPayload});
          }
        };
        return [propName, newVal];
      } else {
        return [propName, val];
      }
    }));

const widgets = [
  {
    _id: `Widget-custom`,
    name: "Custom",
    version: "1.0.0",
    djr: {
      __component: "Fragment",
      __props: {
        children: [
          {
            __component: "Typography",
            __props: {
              variant: "h1",
              text: "Inventory",
            },
          },
          {
            __component: "Typography",
            __props: {
              variant: "h2",
            },
            __render: "props.my.text",
            __mapStateToProps: {
              text: "props.my.text",
            },
          },
          {
            __component: "Loader",
            __render: "props.my.text|not",
            __props: {
            }
          },
        ],
      },
    },
    propsSchema: {
      type: "object",
      properties: {
        text: {
          type: "string",
        },
      },
    },
    author: "vfteam",
    description: "Custom DJR",
    images: [],
    appFramework: {
      "widget-category": "Atomics",
      theme: ["MVA10"],
      author: "vfteam",
      description: "Custom DJR",
    },
  },
];

const specializeForId = (djrOrig, id) => {
  const djr = cloneDeep(djrOrig);
  jp.apply(djr, `$..__mapStateToProps`, msp => {
    Object.entries(msp).forEach(([k, v]) => {
      if (v.startsWith(`props.my.`)) {
        // eslint-disable-next-line no-param-reassign
        msp[k] = v.replaceAll("props.my.", `props.${id}.`);
      }
    });
    return msp;
  });
  jp.apply(djr, `$..__render`, v => v.startsWith(`props.my.`) ? v.replaceAll("props.my.", `props.${id}.`) : v);
  return djr;
};

const DjrComponent = ({ name, id, ...props }) => {
  const componentJson = widgets.find(w => w.name === name).djr;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setProps(id, props));
  }, [props, id, dispatch]);
  const djr = specializeForId(componentJson, id);
  return render(djr);
};

const Iterate = ({ iterateeSelector, propName, elemSelector, children }) => {
  const iteratee = useSelector(state => dot.pick(iterateeSelector, state));
  return (
      <>
        {iteratee.flatMap(elem =>
            children.map(child =>
                React.cloneElement(child, {
                  [propName] : (elemSelector ? dot.pick(elemSelector, elem) : elem),
                  id: uuidv4()
                })))}
      </>
  );
}

const registry = {
  Iterate,
  CardHeader: () => <div></div>,
  Box: props => <Box {...props} i18n={TranslationService.moduleT('R10-gr-sample-product-list')}></Box>,
  Acronym,
  Sidebar,
  Router: props => <ConnectedRouter history={history} {...props}></ConnectedRouter>,
  Switch,
  Redirect,
  PrivateRoute,
  OauthCode,
  NotFoundPage,
  Signin,
  Logout,
  Fragment,
  Route,
  Link,
  'drools': DROOLS_wrapper,
}

// eslint-disable-next-line no-plusplus
for (let i = 0; i < widgets.length; i++) {
  const w = widgets[i];
  // eslint-disable-next-line no-param-reassign
  w.component = DjrComponent;
  registry[w.name] = w.component;
}

const render = createDynamicJourneyRenderer({
  plugins: {
    initialSetup: {
      connect,
      selectors,
    },
    preRender: {
      __mapStateToProps: mapStateToPropsPlugin,
      __render: conditionalRenderingPlugin,
    },
    propsPlugin: componentRenderingPlugin,
  },
  registry
});

export { render, registry };
